"use client";
import React from "react";
import "../animation.scss";
import Image from "next/image";

const Loading = () => {
  return (
    <div className="min-h-screen w-full flex items-center justify-center ">
      <div className="container mx-auto w-full flex items-center justify-center">
        <div className="relative w-[150px] h-[150px]">
          <Image src="/assets/loader/loader.svg" alt="loader" fill />
        </div>
      </div>
    </div>
  );
};

export default Loading;
